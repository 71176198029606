const routes = [
    { path: '/', redirect: '/dashboard' },
    { path: '/dashboard', name: 'dashboard', component: () => import('@/views/Dashboard.vue') },
    { path: '/stations', name: 'station-list', component: () => import('@/views/StationList.vue') },
    { path: '/stations/:id', name: 'station', component: () => import('@/views/Station.vue') },
    { path: '/units', name: 'unit-list', component: () => import('@/views/UnitList.vue') },
    { path: '/units/:id', name: 'unit', component: () => import('@/views/Unit.vue') },
    { path: '/bidders', name: 'bidder-list', component: () => import('@/views/BidderList.vue') },
    { path: '/owners', name: 'owner-list', component: () => import('@/views/OwnerList.vue') },
    { path: '/traders', name: 'trader-list', component: () => import('@/views/TraderList.vue') }
];

export { routes };
export default routes;
